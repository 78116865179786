import Products from '../components/products/Products';

export default function ShopPage() {
  return (
    <div className="shop-page">
      <h1>Products</h1>
      <Products />
    </div>
  );
}
